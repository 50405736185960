import {request} from "@/network/request";
export function getIndexes(){
    return request({
        params: {},
        method:'get',
        url:'/daily/indexes'
    })
}
export function getRanks(){
    return request({
        params: {},
        method:'get',
        url:'/daily/ranks'
    })
}
export function updateRanks(data){
    return request({
        data: data,
        method:'post',
        url:'/daily/updateRanks'
    })
}
export function deleteIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/daily/deleteIndex'
    })
}
export function addIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/daily/addIndex'
    })
}
export function updateIndex(data){
    return request({
        params: data,
        method:'post',
        url:'/daily/updateIndex'
    })
}

export function deleteRank(data){
    return request({
        params: data,
        method:'post',
        url:'/daily/deleteRank'
    })
}
