<template>
    <div style="padding: 10px;">
        <v-card elevation="1" style="padding:10px;">
            <div>
                <span style="font-size: 20px;margin-top: 20px">日常指标管理</span>
                <div style="float: right">
                    <v-btn color="primary" @click="addDialogVisible=true">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        添加指标
                    </v-btn>
                    <v-btn color="primary" @click="rankDialog=true" style="margin: 0 10px">
                        等级设置
                    </v-btn>
                </div>
            </div>
            <el-table el-table :data="tableItems" style="width: 100%;height:700px;margin:5px 0">
                <el-table-column label="指标编号" align="center">
                    <template slot-scope="scope">
                        {{scope.row.indexId}}
                    </template>
                </el-table-column>
                <el-table-column prop="indexName" label="指标名" align="center"></el-table-column>
                <el-table-column prop="parentName" label="指标类型" align="center"
                                 :filters="indexes.typeFilters"
                                 :filter-method="filterHandler"></el-table-column>
                <el-table-column prop="des" label="指标描述" align="center"
                ></el-table-column>
                <el-table-column prop="weight" label="指标权重" align="center"
                ></el-table-column>

                <el-table-column prop="operate" label="操作" width="145" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="showEditDialog(scope.$index,scope.row)">修改
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteDialog(scope.$index,scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="Page.currentPage"
                    :page-sizes="[1, 5, 10, 20]"
                    :page-size="Page.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Page.total">
            </el-pagination>
            <el-dialog
                    title="添加指标"
                    :visible.sync="addDialogVisible"
                    width="30%">
                <el-form :model="addForm" ref="addForm" label-width="100px" :rules="editFormRules"
                         class="demo-ruleForm" style="margin-left: 40px">
                    <el-form-item label="指标名" prop="indexName">
                        <el-input v-model="addForm.indexName" style="width: 65%" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="指标类型" prop="parentName">
                        <el-select v-model="addForm.parentName" clearable placeholder="请选择" style="width: 65%">
                            <el-option v-for="item in indexes.typeFilters" :key="item.text" :label="item.text"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="指标描述" prop="des">
                        <el-input v-model="addForm.des" style="width: 65%" placeholder="请输入"></el-input>

                    </el-form-item>
                    <el-form-item label="指标权重" prop="weight">
                        <el-input v-model="addForm.weight" style="width: 65%" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addDialogVisible = false,addIndex()">确 定</el-button>
                </span>
            </el-dialog>
            <v-dialog
                    scrollable
                    title="等级设置"
                    v-model="rankDialog"
                    width="30%"
            >
                <v-card>
                    <v-card-title>
                        等级设置
                    </v-card-title>
                    <v-card-text>
                        <el-table
                                :data="ranks"
                                style="width: 100%">
                            <el-table-column
                                    prop="id"
                                    align="center"
                                    width="50"
                                    label="编号">
                                <template slot-scope="scope">
                                    {{scope.row.infoRankId }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    align="center"
                                    label="名称">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.infoRankName">

                                    </el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="score"
                                    align="center"
                                    label="权重">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.score"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="score"
                                    align="center"
                                    label="删除">
                                <template slot-scope="scope">
                                    <v-btn
                                            icon
                                            color="error"
                                            @click="deleteRank(scope.$index,scope.row)"
                                    >
                                        <v-icon>mdi-close-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                            </el-table-column>
                        </el-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="addNewRow" color="primary" icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <el-button @click="rankDialog = false">取 消</el-button>
                        <el-button type="primary" @click="rankDialog = false,updateRank()">确 定</el-button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <el-dialog
                    title="提示"
                    :visible.sync="deleteDialogVisible"
                    width="30%"
            >
                <span>您确定要删除该指标？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="deleteDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="deleteDialogVisible = false,deleteIndex()">确 定</el-button>
                </span>
            </el-dialog>
            <el-dialog
                    title="修改"
                    :visible.sync="editDialogVisible"
                    width="40%">
                <el-form :model="editForm" ref="editForm" label-width="100px" :rules="editFormRules"
                         class="demo-ruleForm" style="margin-left: 40px">
                    <el-form-item label="指标名" prop="indexName">
                        <el-input v-model="editForm.indexName" style="width: 65%" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="指标类型" prop="parentName">
                        <el-select v-model="editForm.parentName" clearable placeholder="请选择" style="width: 65%">
                            <el-option v-for="item in indexes.typeFilters" :key="item.text" :label="item.text"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="指标描述" prop="des">
                        <el-input v-model="editForm.des" style="width: 65%" placeholder="请输入"></el-input>
                    </el-form-item>
                    <el-form-item label="指标权重" prop="weight">
                        <el-input v-model="editForm.weight" style="width: 65%" placeholder="请输入"></el-input>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogClosed">取 消</el-button>
          <el-button type="primary" @click="editDialogInfo,updateIndex()">确 定</el-button>
  </span>
            </el-dialog>
        </v-card>
    </div>
</template>

<script>
    import {
        deleteRank,
        updateRanks,
        getRanks,
        getIndexes,
        deleteIndex,
        addIndex,
        updateIndex,
    } from "@/network/Details/Super_admin/dailyIndexManage.js";

    export default {
        created() {
            getIndexes({}).then(res => {
                this.indexes = res.data.indexes
                this.Page.total = this.indexes.items.length
                this.setTableData(1)
            }).catch(err => {
                console.log(err);
            })
            getRanks({}).then(res => {
                this.ranks = res.data
            }).catch(() => {
                console.log(123);
            })
        },
        data() {
            let checkPhone = (rule, value, cb) => {
                const regPhone = /^1[0-9]{10}$/
                if (regPhone.test(value)) {
                    return cb()
                }
                cb(new Error('请输入合法电话'))
            }
            return {
                addForm: {
                    //添加弹窗
                    des: "",
                    indexId: '',
                    indexName: '',
                    parentId: '',
                    parentName: '',
                    weight: '',
                },
                rankDialog: false,
                deleteDialogVisible: false,
                addDialogVisible: false,//默认隐藏添加的弹窗
                editDialogVisible: false,//默认隐藏修改的弹窗
                deleteIndexNum: '',
                addIndexNum: '',
                addParent: '',
                addParentName: '',
                // 分页相关
                Page: {
                    currentPage: 1,//前往的第几页
                    total: 0,//共多少条
                    size: 10,//每页显示多少条
                },
                tableItems: [],
                employee_name: '',//搜索框
                editFormRules: {
                    phone: [{
                            required: true, message: '请输入电话', trigger: 'blur'
                        }, {
                            validator: checkPhone, trigger: 'blur'
                        }],
                    indexName: [{
                        required: true, message: '请输入姓名', trigger: 'blur'
                    }],
                    des: [{
                        required: true, message: '不能为空', trigger: 'blur'
                    }],
                    weight: [{
                        required: true, message: '不能为空', trigger: 'blur'
                    }],
                    parentName: [{
                        required: true, message: '不能为空', trigger: 'blur'
                    }]
                },
                editForm: {
                    des: "",
                    indexId: '',
                    indexName: '',
                    parentId: '',
                    parentName: '',
                    weight: ''
                },
                //过滤数据的
                parentNameFilters: [],
                indexes: '',
                ranks: '',
            }
        },
        methods: {
            addNewRow() {
                this.ranks.push({
                    infoRankId: 0,
                    infoRankName: "",
                    score: 0
                })
            },
            deleteDialog(index, row) {
                //删除指标
                this.editForm = row
                this.deleteIndexNum = index
                console.log(this.deleteIndex);
                this.deleteDialogVisible = true

            },
            deleteIndex() {
                deleteIndex({
                    indexId: this.editForm.indexId
                }).then(res => {
                    if (res.data === true) {
                        let current = (this.Page.currentPage - 1) * this.Page.size + this.deleteIndexNum
                        this.indexes.items.splice(current, 1)
                        this.setTableData(this.Page.currentPage)
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '删除失败!'
                    });
                });
            },
            updateRank() {
                updateRanks(this.ranks).then(res => {
                    if (res.status === 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                    }
                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '操作失败!'
                    });
                    console.log(err);
                });
            },
            addIndex() {
                for (let i = 0; i < this.indexes.typeFilters.length; i++) {
                    if (this.addForm.parentName === this.indexes.typeFilters[i].value) {
                        this.addParent = this.indexes.typeFilters[i].value
                        this.addParentName = this.indexes.typeFilters[i].text
                    }

                }
                addIndex({
                    indexDes: this.addForm.des,
                    indexId: 0,
                    parentId: this.addParent,
                    indexName: this.addForm.indexName,
                    weight: this.addForm.weight
                }).then(res => {
                    if (res.data.result === "Successfully") {
                        let item = {
                            parentName: this.addParentName,
                            des: this.addForm.des,
                            indexName: this.addForm.indexName,
                            indexId: res.data.indexId,
                            weight: this.addForm.weight,
                            parentId: this.addParent
                        }
                        this.indexes.items.push(item)
                        let filter = {
                            text: this.addForm.indexName,
                            value: res.data.indexId
                        }
                        this.indexes.typeFilters.push(filter)
                        this.$message({
                            type: 'success',
                            message: '添加成功!'
                        });
                        this.addForm = {//添加合同弹窗中的内容
                            des: "",
                            indexId: '',
                            indexName: '',
                            parentId: '',
                            parentName: '',
                            weight: '',
                        }
                    }

                }).catch(err => {
                    this.$message({
                        type: 'info',
                        message: '添加失败'
                    })
                });
            },
            updateIndex() {
                this.editDialogVisible = false
                updateIndex({
                    indexDes: this.editForm.des,
                    indexId: this.editForm.indexId,
                    parentId: this.editForm.parentId,
                    indexName: this.editForm.indexName,
                    weight: this.editForm.weight
                }).then(res => {
                    if (res.data === true) {
                        let item = {
                            parentName: this.editForm.parentName,
                            des: this.editForm.des,
                            indexName: this.editForm.indexName,
                            indexId: this.editForm.indexId,
                            weight: this.editForm.weight,
                            parentId: this.editForm.parentId
                        }
                        this.indexes.items.splice(this.addIndexNum, 0, item)
                        this.$message({
                            type: 'success',
                            message: '修改成功!'
                        });
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message({
                        type: 'info',
                        message: '添加失败'
                    })
                });
            },
            deleteRank(index, id) {

                if (id.infoRankId > 0) {
                    deleteRank({
                        id: id.infoRankId
                    }).then(res => {
                        if (res.data === true) {
                            this.ranks.splice(index, 1)
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message({
                            type: 'info',
                            message: '删除失败'
                        })
                    });
                } else {
                    this.ranks.splice(index, 1)
                }

            },
            setTableData(current) {
                if (current < 1)
                    this.tableItems = [];
                let pageSize = this.Page.size;
                let start = (current - 1) * pageSize;
                let end = Math.min(current * pageSize, this.Page.total);
                this.tableItems = this.indexes.items.slice(start, end)
            },
            //筛选框去重的函数
            removeDoubleItem(arr) {
                return Array.from(new Set(arr))
            },
            handleCurrentChange(newPage) {//监听页码值的改变
                this.Page.currentPage = newPage;
                this.setTableData(newPage)
            },
            showEditDialog(index, row) {
                this.editDialogVisible = true;
                this.editForm = row
                this.addIndexNum = index
            },
            resetDateFilter() {
                this.$refs.filterTable.clearFilter('date');
            },
            clearFilter() {
                this.$refs.filterTable.clearFilter();
            },
            formatter(row, column) {
                return row.address;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            filterHandler(value, row, column) {
                const property = column['property'];
                return row[property] === value;
            },
            //重置表单续签
            editDialogClosed() {
                this.$refs.editForm.resetFields();
                this.editDialogVisible = false
            },
            editDialogInfo() {
                this.$refs.editForm.validate(valid => {
                    if (!valid) return
                    //发起数据请求
                    updateStaff({
                        name: this.editForm.name,
                        phone: this.editForm.phone,
                        email: this.editForm.email,
                        pTitle: this.editForm.ptitle,
                        workNum: this.editForm.uid
                    }).then(res => {
                        if (res.data.result === 'Successfully') {
                            this.$message.success("更改成功")
                        } else {
                            this.$message.error("更改失败")
                        }
                        this.updateTable()
                    }).catch(err => {
                        this.$message.error("更改失败，请检查网络")
                        console.log(err)
                    })
                    this.editDialogVisible = false
                })
            },
            closeAddForm() {
                this.addDialogVisible = false
                this.$refs.addFormRef.resetFields()
            }
        },
        name: "DailyIndexManage",
    }
</script>

<style scoped>

</style>
